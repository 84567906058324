import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function InscriptionFluxPage() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = [
        {
            label: "Téléchager l'application Twizzit",
            description: (
                <>
                    <Typography>
                        Sur votre smartphone, télécharger l'appplication Twizzit via l'AppStore (Iphone) ou via le Play Store (Android).
                    </Typography>

                    <img src=""  />
                </>
            ),
        },
        {
            label: "Créer un compte Twizzit",
            description: (
                <>

                        <ul>
                            <li>Ouvrez l'application Twizzit</li>
                            <li>Cliquez sur "Vous n'avez pas de nom d'utilisateur"</li>
                            <li>Recherchez l'organisation en tapant <u>"Union Athlétique Châtelineau"</u></li>
                            <li>Entrez votre email, prénom et nom</li>
                                <ul>
                                    <li>Si la LBFA vous a trouvé, cliquer sur "Demander Login"</li>
                                    <li>Si la LBFA ne vous pas trouvé, compléter votre compte</li>
                                </ul>
                            <li>Un mail vous sera envoyé pour confirmer le compte, cela peut prendre jusque 24h</li>
                            <li>Cliquer sur le mail et mettre un nom d'utilisateur et mot de passe</li>
                        </ul>

                </>
            ),
        },
        {
            label: "Créer son affiliation",
            description: (
                <>

                    <ul>
                        <li>Aller dans l'application Twizzit et se connecter</li>
                        <li>Cliquez sur "Offre" et sur "Affiliation à la LBFA"</li>
                        <li>Remplisser vos données</li>
                        <li>Dans <b>type d'affiliation</b>, choisissiez</li>
                        <ul>
                            <li><b>Compétitive</b> pour tous les athèles</li>
                            <li><b>Classic</b> pour tous les athlètes</li>
                        </ul>
                        <li>Pour le prix, choisisez :</li>
                        <ul>
                            <li>110€ pour les kangourous</li>
                            <li>145€ pour les benjamins, pupilles, minimes</li>
                            <li>125€ pour les cadets à masters</li>
                        </ul>
                    </ul>

                </>
            ),
        },
        {
            label: 'Payer la cotisation',
            description: (
                <>
                    <ul>
                        <li>Payez la cotisation sur le compte du club</li>
                        <li>Il doit se faire sur le compte de l’ASBL BE88 1420 6309 0441 pour le 30.10.2024</li>
                        <li>1 versement par athlète (s’il s’agit d’un enfant, mentionner le nom en communication)</li>
                    </ul>

                </>
            ),
        },
    ];


    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Comment s'inscire en ligne ?</span></h1>
                </Col>
            </Row>
            <Row className='mt-4 bloc' xs={1} md={1} lg={1}>
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Informations pratiques</span></h1>
                </Col>
                <Col className='mt-3'>
                    ℹ️ Les inscriptions se font <b>totalement en ligne.</b> Pas besoin de papier d'inscription au club ou d'attestation sur l'honneur. <br/>
                    ℹ️ Le paiement se fait pour l'instant via le compte en banque du club. Dans quelques jours, ça sera via l'application directement.<br/>
                    ℹ️ Pas de panique, les inscriptions se font <b>jusqu'au 30 octobre.</b> <br/>
                    ℹ️ Cette procédure est imposée par la ligue.
                </Col>
            </Row>
            <Row className='mt-4 bloc' xs={1} md={1} lg={1}>
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Procédure d'inscription</span></h1>
                </Col>
                <Col className='mt-3'>
                    <Box>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === steps.length - 1 ? (
                                                <Typography variant="caption">Dernière étape</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>

                                        <Box sx={{ mb: 2 }}>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {index === steps.length - 1 ? 'Terminer' : 'Continuer'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Retour
                                            </Button>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>Toutes les étapes sont complétées !</Typography>
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Recommencer
                                </Button>
                            </Paper>
                        )}
                    </Box>
                </Col>

            </Row>
        </Container>
    )
}

export default InscriptionFluxPage