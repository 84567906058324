import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

const EventPage = () => {

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Soirée des étoiles | UAC";
    }, []);

    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Soirée des Étoiles 2024</span></h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Présentation</span></h3><br />
                    <p>
                        La soirée des étoiles de l'UAC est l'événement incontournable du club, où nous mettons en lumière les performances de la saison précédente. C'est l'occasion de célébrer les exploits des athlètes qui ont repoussé leurs limites pour atteindre l'excellence sur la piste, le terrain et dans les airs.
                    </p>
                    <p>
                        Cette soirée est d'abord une remise de prix où les athlètes seront récompensés. Elle vous permettra aussi de partager un délicieux repas en compagnie des athlètes, des entraîneurs, des amis et des familles, créant ainsi des souvenirs précieux. Rejoignez-nous le 16 novembre pour célébrer la réussite de nos athlètes.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Informations pratiques</span></h3><br />
                    <ul>
                        <li><b>Le 16 Novembre 2024 à 18h</b></li>
                        <li>Repas enfant (-12 ans) : Cordon bleu, purée, compote (15 €)</li>
                        <li>Repas adulte : Porchetta, légumes et gratin dauphinois (20 €)</li>
                        <li>Apéro & Dessert/café inclus</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Inscriptions</span></h3><br />
                    <p>
                        <ul>
                            <li>Inscription et paiement obligatoire <u>avant le 09 novembre</u></li>
                            <li><b>La confirmation de l'inscription est le paiement</b></li>
                        </ul>
                    </p>

                    {/*                     <Button className="mt-3 buttonJauneExterne" size="lg" onClick={() => { navigate(`/SoireeDesEtoiles/annulation/64e12db609a1828c1ee3cdab`) }} >Modifier / Annuler <FontAwesomeIcon icon={faArrowRight} /></Button> {` `} */}

                    <Button className="mt-3 buttonJaune" size="lg" onClick={() => { navigate("/SoireeDesEtoiles/inscription") }}>Inscription<FontAwesomeIcon icon={faArrowRight} /></Button>
                </Col>
            </Row>
        </Container>
    )
}

export default EventPage